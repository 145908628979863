.deviceoffline-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
        text-align: center;
        font-size: 28px;
        color: #82b420;
        font-weight: bold;
    }
}
