.localization-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
        text-align: center;
        font-size: 24px;
    }

    h4 {
        font-size: 18px;
    }

    .btn {
        font-size: 24px;
    }

    .volume-info {
        margin-top: 16px;
        font-weight: bold;
        color: #82b420;
        font-size: 24px;
    }
}
